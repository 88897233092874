@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress */
/* ---------------------------------------------------------------- */
.progress {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h3 {
    font-size: 18px;
    line-height: 19px;
    color: $primaryTitle;
    font-family: $mainHeading;
    margin: -2px 0 9px;
  }
  /* ---------------------------------------------------------------- */
  /* Navigation / Progress Indicator */
  /* ---------------------------------------------------------------- */
  > nav {
    display: flex;
    flex-direction: column;
    > ul {
      overflow: hidden;
      margin: 0 0 30px;
      border-radius: 10px;
      border: 1px solid $greyFormBorder;
      > li {
        border-top: 1px solid $greyFormBorder;
        &:first-child{
          border: none;
        }
        h2 {
          padding: 10px 20px;
          font-size: 16px;
          line-height: 22px;
          font-family: $semiboldText;
          color: #282d3d;
          margin: 0 0 2px;
          background-color: $greyXXLight;
        }
      }
    }
  }
}