@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Label */
/* ---------------------------------------------------------------- */
.label {
  display: table;
  color: $primaryTitle;
  font-size: 14px;
  line-height: 19px;
  font-family: $bodyHeading;
  cursor: pointer;
  margin: -4px 0 8px;
  position: relative;
  max-width: 400px;
  /* ---------------------------------------------------------------- */
  /* Asterix */
  /* ---------------------------------------------------------------- */
  span {
    margin: 0 0 0 3px;
    color: $error;
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    color: rgba($primaryTitle, 0.7);
    pointer-events: none;
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    color: rgba($primaryTitle, 0.7);
    pointer-events: none;
  }
}
