@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.radio-buttons {
  display: flex;
  
  &.is-invalid {
    .label {
      
      > div {
        border-color: $error;
        color: $error;
      }

      input {
        &:focus {
          + div:before {
            border-color: $error;
          }
        }
      }
    }
  }

  &.read-only {
    pointer-events: none;
    opacity: .7;
  }

  > label {
    flex: 1 1 100%;
  }

  .label {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    color: $black;
    font-family: $bodyHeading;
    padding: 10px 0 10px 42px;
    height: 43px;
    box-sizing: border-box;

    & + .label {
      margin-left: 10px;
    }

    > div {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #C4C5CA;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding-left: 42px;
      
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:focus {
        + div {
          &::before {
            content: "";
            height: calc(100% + 8px);
            width: calc(100% + 8px);
            z-index: 0;
            position: absolute;
            top: -5px;
            left: -5px;
            border: 1px solid $primary;
            border-width: 1px;
            border-style: solid;
            border-color: $primary;
            box-sizing: content-box;
            display: block;
            border-radius: 6px;
            pointer-events: none;
            transition-duration: 0.1s;
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            transition-property: border-color;
          }
          
        }
        
      }

      &:checked {
        + div {
          background-color: rgba($primary, .05);
          border-color: $primary;

          &::before {
            display: none;
          }

          span {
            &::before {
              background-color: transparent;
              border-color: $primary;
            }
            &::after {
              display: block;
            }
          }
        }

      }
    
    }

    span {
      
      &::before {
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 13px;
      } 

      &:after {
        content: "";
        display: none;
        background: $primary;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
      }
    }
  }
}