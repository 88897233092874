@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Status Bar */
/* ---------------------------------------------------------------- */
.status {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  left: -1px;
  top: 0;
  width: 11px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 100%;
    top: 0;
  }
  &::before {
    left: 0;
    width: 4px;
    background-color: transparent;
    @include slow-transition($props: "background-color");
  }
  &::after {
    left: 4px;
    width: 8px;
  }
  /* ---------------------------------------------------------------- */
  /* Require */
  /* ---------------------------------------------------------------- */
  &.required {
    &::before {
      background-color: $required;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Answered */
  /* ---------------------------------------------------------------- */
  &.answered {
    &::before {
      background-color: $valid;
    }
  }
}
