/* User Select */
@mixin user-select() {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* No User Select */
@mixin no-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Fast Transition */
@mixin fast-transition($ie: false, $mob: false, $timing: cubic-bezier(0.42, 0, 0.58, 1), $props: "all") {
  transition-duration: 0.1s;
  transition-timing-function: #{$timing};
  transition-property: #{$props};
  @if not($ie) {
    :global(.browser-ie) & {
      transition: none;
    }
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}

/* Slow Transition */
@mixin slow-transition($ie: false, $mob: false, $timing: cubic-bezier(0.42, 0, 0.58, 1), $props: "all") {
  transition-duration: 0.3s;
  transition-timing-function: #{$timing};
  transition-property: #{$props};
  @if not($ie) {
    :global(.browser-ie) & {
      transition: none;
    }
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
