@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Debug */
/* ---------------------------------------------------------------- */
.debug {
  display: block;
  font-size: 11px;
  line-height: 13px;
  color: #333333;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 0 -3px;
}
