@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.form-control {

  > h4, > label {
    display: block;
    font-size: 14px;
    font-family: $bodyHeading;
    letter-spacing: -.2px;
    line-height: 19px;
    color: $greyXXDark;
    margin-bottom: 6px;

    > span {
      color: $error;
      font-size: 16px;
    }
    + p {
      margin-bottom: 10px;
      margin-top: -5px;
    }
  }

  &.is-disabled {
    > p:not(.sublabel) {
      font-size: 14px;
      margin-top: 6px;
      color:$error;
      letter-spacing: -.2px;
      line-height: 19px;
    }
  }

  &.read-only {
    pointer-events: none;
  }

  &.selected-level {
    border-left: 2px solid $required !important;

    &::before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: $required;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

}
