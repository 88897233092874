@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress */
/* ---------------------------------------------------------------- */
.progress {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h3 {
    font-size: 18px;
    line-height: 19px;
    color: $primaryTitle;
    font-family: $mainHeading;
    margin: -2px 0 9px;
  }
  /* ---------------------------------------------------------------- */
  /* Navigation / Progress Indicator */
  /* ---------------------------------------------------------------- */
  > nav {
    display: flex;
    flex-direction: column;
    > ul {
      overflow: hidden;
      margin: 0 0 15px;
      border-radius: 10px;
    }
  }
}