@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.tabContent {
  display: flex;
  > * {
    flex: 1 1 100%;
  }

  > section {
    > div {
      border-radius: 0 0 12px 12px;
    }
  }

  > aside {
    > div {
      &:first-of-type {
        margin-top: 0!important;
        padding-top: 0!important;

        > div > div {
          justify-content: center;

          > h2 {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
.resultsTop {
  display: flex;
  justify-content: space-between;
}
.tabContainer > ul{
  display: flex;
  align-items: flex-end;
  li {
    padding: 8px 20px;
    min-width: 100px;
    border: 1px solid $greyFormBorder;
    border-bottom: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
    cursor: pointer;
    font-family: $mainHeading;
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: -1px;
    z-index: 9;
    color:$greyXXXDark;
    &.active {
      background-color: $white;
      color:$primary;
      padding: 10px 20px;
    }
  }
}

.improvement {
  display: flex;
  align-items: center;

  > svg {
    margin-right: 10px;
  }
  > h3 {
    color: $greyXXXDark;
    font-family: $mainHeading;
  }
}

.topCtaContainer {
  display: flex;
  button:last-child {
    margin-left: 12px;
  }
}