@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Buttons */
/* ---------------------------------------------------------------- */
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -10px;
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  > li {
    margin: 0 5px 10px;
  }
  /* ---------------------------------------------------------------- */
  /* Radio / Checkbox */
  /* ---------------------------------------------------------------- */
  &.radio,
  &.checkbox {
    /* ---------------------------------------------------------------- */
    /* Vertical */
    /* ---------------------------------------------------------------- */
    &.vertical {
      flex-direction: column;
      flex-wrap: nowrap;
      > li {
        width: 355px;
        flex-shrink: 0;
        > button,
        div[role="button"] {
          height: auto;
          padding: 7px 9px;
          span {
            white-space: normal;
            text-align: left;
            align-items: flex-start;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Horizontal */
    /* ---------------------------------------------------------------- */
    &.horizontal {
      > li {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Select All */
    /* ---------------------------------------------------------------- */

    &.select-all {
      > li {
        &:first-of-type {
          margin-left: 5px;
          width: 370px;
        }
        margin-left: 20px;
        
      }
    }
  }
  &.radio.horizontal {
    > li {
      flex:initial;
    }
  }
  &.radio.vertical {
    span {
      &::before {
        margin: 2px 12px 0 0;
      }
      &::after {
        top: 5px;
      }
    }
  }
  &.checkbox.vertical {
    span {
      &::before {
        margin: 1px 12px 0 0;
      }
      &::after {
        top: 2px;
      }
    }
  }
}

.row {
  margin: 0;
  
  > li {
    margin: 0;
    + li {
      margin-left: 20px;
    }

    button,
    .button {
      height: 20px;
      background-color: transparent!important;
      border: none!important;
      padding: 0!important;
      &:hover {
        background-color: transparent!important;
      }
    }

  }

}