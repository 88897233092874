@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.Modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #eee, $alpha: 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;

  .ModalBox {
    position: relative;
    display: block;
    margin: 0 0 0 0;
    padding: 40px 40px 40px 40px;
    max-width: 530px;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid $greyFormBorder;
    border-radius: 12px;
    box-sizing: border-box;

    .ModalBoxTop {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > svg {
        width: 32px;
        height: 32px;
      }

      .ModalClose {
        width: 10px;
        height: 10px;
        line-height: 14px;
        cursor: pointer;
      }
    }
    
    .ModalBoxBody{
      padding: 30px 0;
      h2{
        font-family: $semiboldHeading;
        font-size: 18px;
        line-height: 20px;
        color: $greyXXXDark;
      }
      p{
        margin-top: 20px;
        font-family: $regularText;
        font-size: 14px;
        color: rgba($color: #000000, $alpha: .7);
      }
    }
    .ModalBoxBottom {
      > button, .button {
        + button, + div {
          margin-left: 20px;
        }
      }
    }
  }
}