@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.panel {
  border-radius: 12px;
  padding: 8px 0 20px;
  background-color: $white;
  border: 1px solid rgba(#c4c5ca, 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  &.is-open {
    padding-top: 28px;
    cursor: default;
    .panel {
      &__header {
        align-items: flex-start;
        h3 {
          margin: 0 8px 14px 0;
          max-width: initial;
          width: 100%;
        }
      }
      &__info {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;

        &-company {
          flex-direction: row;
          width: 100%;

          div {
            + div {
              margin-left: 24px;
            }

            span {
              strong {
                display: block;
              }
            }

            span + span {
              margin-top: 5px;
            }
          }
        }
      }

      &__action {
        max-width: 365px;
        > div[role="button"],
        > button {
          &:last-child {
            margin-left: 16px;
          }
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    font-family: $bodyHeading;
    line-height: 22px;
    color: $black;
  }

  + .panel {
    margin-top: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0 24px;
    @media (max-width: 1146px) {
      padding: 0 14px;
    }
    h3 {
      display: inline-flex;
      min-width: 180px;
      padding-right: 30px;
      box-sizing: border-box;
      @media (max-width: 1132px) {
        min-width: 170px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
    margin-top: 20px;

    &-numbers {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        + div {
          margin-left: 32px;
        }
      }

      span {
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        + span {
          margin-top: 8px;
        }
      }
    }

    &-company {
      display: flex;
      > div {
        display: flex;
        flex-direction: column;
      }
    }

    span {
      font-size: 14px;
      strong {
        font-family: $bodyHeading;
        color: $black;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 210px;
    margin-left: auto;
  }

  &__toggle {
    margin-left: auto;
    width: 32px;
    height: 42px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      cursor: pointer;
      transition: transform 0.2s;
      &.isOpen {
        transform: rotate(180deg);
      }
      &.isClosed {
        pointer-events: none;
      }
    }
  }

  &__body {
    margin-top: 32px;

    &:empty {
      margin: 0;
    }
  }

  &__tabs {
    display: inline-flex;
    align-items: flex-end;
    z-index: 2;
    margin-bottom: -1px;
  }

  &__tab {
    border: 1px solid rgba($grey, 0.5);
    border-bottom-color: transparent;
    border-radius: 12px 12px 0 0;
    padding: 7px 20px 6px;
    margin-left: -1px;
    font-family: $mainHeading;
    line-height: 15px;
    font-size: 12px;
    color: $greyXXXDark;
    cursor: pointer;
    text-transform: capitalize;

    &.isActive {
      color: $primary;
      border-color: $grey;
      border-bottom: 1px solid $white;
      z-index: 1;
      padding: 9px 20px;
    }

    &.isDisabled {
      cursor: auto;
      color: $grey;
    }
  }

  &__headings {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 17px;
    color: $greyXDark;
    border-top: 1px solid rgba($grey, 0.5);
    border-bottom: 1px solid rgba($grey, 0.5);
    margin-top: -1px;
    align-items: flex-end;
    padding: 18px 24px 6px;

    > * {
      flex: 1 1 100%;
    }
  }

  &__heading {
    &Title {
      max-width: 160px;
      min-width: 120px;
    }

    &Perspectives {
      max-width: 342px;
      min-width: 342px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      margin-right: 12px;
      span {
        width: 100%;
      }
      div {
        width: 45px;

        &.tooltip {
          > span {
            top: -170%;
          }
        }
      }
    }

    &StartDate {
      max-width: 80px;
      margin-left: auto;
    }

    &FinishDate {
      max-width: 80px;
    }

    &Actions {
      max-width: 164px;
      min-width: 164px;
      display: flex;
      margin-left: 12px;
    }
  }

  &__assessments {
    width: 100%;
    border-collapse: collapse;
    border-color: transparent;
    max-width: 100%;
    border-top: 1px solid rgba($grey, 0.5);

    + .panel__assessments {
      border-top: none;
    }

    thead 
    {
      tr {
        vertical-align: bottom;
      }
      th {
        font-weight: normal;
        font-size: 12px;
        color: $greyXDark;
        line-height: 17px;
        border-bottom: 1px solid rgba($grey, 0.5);
        height: 51px;
        padding-bottom: 5px;
        text-align: left;

        > h4 {
          font-size: 16px;
          font-family: $mainHeading;
          color: $black;
          text-transform: uppercase;
        }
        > span {
          display: block;
        }

        > div {
          display: flex;
          // justify-content: center;
          > div {
            width: 45px;
          }
          span {
            padding: 0 4px;
          }
        }
        &:first-of-type {
          padding-left: 24px;
          width: 164px;
        }

        &:nth-of-type(2) {
          width: 340px;
        }

        &:nth-of-type(3) {
          padding-left: 30px;
          width: 140px;
        }

      }

    > div {
      padding: 8px 24px;
    }
  }
  }
  > tbody {
    width: 100%;
  }
}

.assessment {
  border-bottom: 1px solid rgba($grey, 0.5);
  background-color: $primaryPastel;

  &:nth-of-type(even) {
    background-color: $white;
  }
  > td {
    height: 73px;
    &:first-of-type {
      padding-left: 24px;
    }  
    &:last-of-type {
      padding-right: 24px;
    }  
  }

  &__name {
    width: auto;

    > div {
      display: flex;
      align-items: center;
      svg {
        min-width: 16px;
        margin-right: 6px;
      }
    }

    h3 {
      white-space: normal;
      font-size: 14px;
      font-family: $bodyHeading;
      line-height: 19px;
      color: $black;
    }

    > span {
      font-size: 13px;
      color: $greyXXXDark;
      line-height: 17px;
    }

    p {
      font-size: 11px;
      color: $greyXXXDark;
      line-height: 14px;
      span {
        font-family: $bodyHeading;
        color: $primary
      }
    }
  }

  &__perspectives {
    max-width: 342px;

    &.itil {
      display: flex;
      align-items: center;
    }
    
    > div {
      display: flex;

        div {
          width: 43px;
          margin: 0 1px;
          box-sizing: border-box;
          overflow: hidden;
          background-color: $greyXLight;
          position: relative;
          padding: 1px 0;
          font-family: $bodyHeading;
          font-size: 13px;
          letter-spacing: -0.2px;
          z-index: 3;
          color: $black;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
      
            &.tag_white {
              background-color: $primaryPastel;
            }
            &.tag_warning {
              background-color: $required;
            }
            &.tag_done {
              background-color: $primary;
            }
          }
        }

        &.progressBar {
          flex-direction: column;
          width: 160px;
          align-self: center;

          &+.progressBar {
            margin-left: 20px;
          }
          
          > span {
            font-size: 12px;
            color: $greyXDark;
            margin-bottom: 6px;
          }
        
          > div {
            border-radius: 10px;
            height: 10px;
            width: 100%;
            > span {
              border-radius: 10px;
              
              &:first-of-type {
                z-index: 3;
              }
            }
          }
        
        }
      
    }


  }

  &__startDate {
    padding-left: 30px;
  }

  &__startDate,
  &__finishDate {
    color: $black;
    font-size: 12px;
  }


  &__empty {
    text-align: center;
    padding: 12px 0;
  }



  &__actions {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      
      &Cerfify {
        cursor: pointer;
        margin-left: auto;
        display: flex;
        .tooltip {
          display: flex;
        }
      }
  
      > div[role="button"],
      > button,
      > a {
        &:first-child {
          margin-right: 16px;
        }
      }
    }
    &Cerfify{
      cursor: pointer;
    }

    &Settings {
      cursor: pointer;
      display: flex;

      &.assessment__dropOut{
        margin-left: auto;
      }

      &:only-child {
        margin-left: auto;
      }
      &:hover {
        svg path {
          transition: fill 0.2s;
          fill: $greyXXXDark;
        }
      }
    }

    &Locked {
      display: flex;
      > div {
        position: relative;
        width: 32px;
      }
      > svg {
        width: 16px;
        min-width: 16px;
        margin-right: 16px;
      }
      > span {
        font-size: 12px;
        max-width: 110px;
       
      }
      .button, button {

        span {
          font-size: 12px;
        }
        
      }
    }
   
  }
}

.loading {

  > .spinner {
      opacity: 1;
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      top: 50%;
      margin: -12px 0 0 0;
      width: 24px;
      height: 24px;
      fill: transparent;
      stroke: $primary;
  
      circle {
        fill: transparent;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
        stroke: $primary;
      }
    
  }

  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}

.tooltip {
  position: relative;
  > span {
    background-color: $black;
    border: 1px solid $grey;
    border-radius: 4px;
    color: $white;
    white-space: nowrap;
    position: absolute;
    top: -120%;
    width: auto;
    padding: 3px 8px;
    font-size: 12px;
    font-family: $bodyHeading;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    &::before {
      content: "";
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: -6px;
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid $black;
    }
  }
  > h4,
  > svg {
    font-size: 12px;
    line-height: 17px;
    color: $greyXDark;

    &:hover {
      + span {
        display: block;
      }
    }
  }
}


