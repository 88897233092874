@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.TooltipContainer {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 50%;
  border: 2px solid #767983;
  color: #767983;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;  
  .tooltip {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    > span {
      position: absolute;
      bottom: calc(100% + 9px);
      left: 50%;
      display: block;
      padding: 10px 10px;
      width: auto;
      background-color: $black;
      border: 1px solid $grey;
      border-radius: 4px;
      color: $white;
      white-space: nowrap;
      font-size: 12px;
      font-family: $regularText;
      transform: translateX(-50%);
      z-index: 99;

      &::before {
        content: "";
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: -6px;
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid $black;
      }
    }
  }
}
