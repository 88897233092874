@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.headings {
  max-width: 650px;
  width: auto;

  > h1 {
    font-family: $mainHeading;
    color: $black;
    font-size: 22px;
    line-height: 27px;

    + p {
      margin-top: 5px;
    }
  }

  > p {
    font-family: $mainHeading;
    color: $greyXDark;
    font-size: 18px;
    line-height: 22px;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    > div + div  {
      margin-left: auto;
      width: auto;
      display: inline-block;
    }
  }
}
