@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* PageWrapper */
/* ---------------------------------------------------------------- */
.wrapper {
  position: relative;
  background-color: $white;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 650px;
  border: 1px solid $greyFormBorder;
  
  /* ---------------------------------------------------------------- */
  /* PageWrapper - Title Wrapper */
  /* ---------------------------------------------------------------- */
  .title {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid $greyFormBorder;
    box-sizing: border-box;
    
    /* ---------------------------------------------------------------- */
    /* PageWrapper - Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $secondaryTitle;
      font-family: $mainHeading;
      font-size: 16px;
      line-height: 19px;
      // text-transform: capitalize;
      position: relative;
      padding: 0 32px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset - Rows */
  /* ---------------------------------------------------------------- */
  // & > div{
  //   background-color: $white;
  //   border-style: solid;
  //   border-width: 1px 1px 0;
  //   border-color: $greyFormBorder;
  //   padding: 24px 32px;
  //   position: relative;
  //   &:nth-child(odd) {
  //     background-color: $greyXXLight;
  //   }
  //   &:last-child {
  //     border-bottom-left-radius: 12px;
  //     border-bottom-right-radius: 12px;
  //     border-bottom: 1px solid $greyFormBorder;
  //   }
  // }
  
}