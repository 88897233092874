@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.table-outter-label {
  margin: 20px 0 15px 0;
  font-family: $mainHeading;
  font-size: 12px;
  color: $greyXXXDark;
}

.Table {
  margin-top: 14px !important;
  padding: 10px 0;
  font-size: 14px;
  color: $greyXXXDark;
  overflow: hidden;
  
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid $greyFormBorder;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    h2 {
      font-family: $bodyHeading;
      font-size: 14px;
      color: $greyXXXDark;
      + svg {
        width: 22px;
        height: 26px;
      }
    }
  }

  &__wrapper {
    table {
      border-collapse: collapse;
      width: 100%;
      min-width: 300px;
    }

    thead {
      > tr {
        th {
          font-family: $mainHeading;
          + th {
            border-left: 1px solid $greyFormBorder;
           
          }
        }
      }
    }

    tbody {
      > tr {
        vertical-align: top;
        td + td {
          border-left: 1px solid $greyFormBorder;
        }

        th + td {
          border-left: 1px solid $greyFormBorder;
        }

        &:last-child {
          td, th {
            border-bottom: none;
          }
        }
      }
    }

    td,
    th {
      border-bottom: 1px solid $greyFormBorder;
      text-align: left;
      padding: 16px 20px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: normal;

      p {
        color: $greyXXXDark;
        font-size: 12px;
        + p {
          margin-top: 20px
        }
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #f9fdfc;
    }

    &.Table__small {
      table th,
      table td {
        padding: 6px 10px;
      }

      tr {
        > th, td {
          vertical-align: middle;
          height: 44px;

          &:first-of-type,
          &:last-of-type,
          &:nth-of-type(3) {
            text-align: center;
          }
        }

        td {
          &.positive {
            color: $primary;
          }
          &.negative {
            color: $error;
          }
          &.even {
            color: $required;
          }
        }
        &.finalScores {
          td {
            width: 50%;
            text-align: center;
            height: 78px;
            strong {
              display: block;
              font-size: 24px;
              color: $greyXXXDark;
              font-family: $mainHeading;
            }
          }
        }
      } 
    }

    // Rows background filled
    .Table__full-bg tbody tr {
      background-color: #f9fdfc;
    }
    .Table__no-bg tbody tr {
      background-color: #ffffff;
    }
   
  }
  // No Heading
  &.Table__headless{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &.Table__no-download{
    padding-bottom: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &.Table__no-body {
    .Table__wrapper,
    .Table__header > h2 {
      display: none;
    }
  }
  &.Table__w-score {
    .Table__wrapper {
      tbody tr td:last-of-type {
        text-align: center;
      }
    }
  }
  &.Table__group{
    padding-top: 0;
  }
  &__image-export {
    display: flex;
    margin-top: -10px !important;
    padding: 15px 20px;
    border: 1px solid $greyFormBorder;
    border-top: 0;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    > button, > .button {
      width: 100%;
    }

    &.no-border {
      border: none;
      padding:20px 0 0 ;
      align-items: flex-end;
      
      > button, > .button {
        width: auto;
        margin-left: auto;
      }

    }
  }
}

.benchmarkIcon{
  margin-right: 5px;
}

.TableBorder {
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid $greyFormBorder;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  // No Heading
  .Table__headless &{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .Table__no-download &{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.csvExport {
  position: relative;
  display: inline-flex;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
  touch-action: manipulation;
  overflow: visible;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-property: border-color, background-color;
  border-color: #2ebfa5;
  background-color: transparent;
  width: 100%;
  &:hover {
    background-color: #e9f9f6;
  }
  &:before {
    content: "";
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    z-index: 0;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 1px solid transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: content-box;
    display: block;
    border-radius: 10px;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transition-property: border-color;
  }
  > svg {
    margin: 0 8px 0 14px;
    height: 16px;
    width: 16px;
    pointer-events: none;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    * {
      fill: #2ebfa5;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      transition-property: fill, stroke;
    }
  }
  span {
    font-family: $semiboldText;
    font-size: 14px;
    line-height: normal;
    white-space: nowrap;
    pointer-events: none;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    transition-property: color;
    position: relative;
    z-index: 1;
    padding: 0 18px 0 0;
    color: #2ebfa5;
  }
}