@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress Section */
/* ---------------------------------------------------------------- */
.progress-section {
  > div {
    background-color: $white;
    border: 1px solid $greyFormBorder;
    border-radius: 10px;
    margin: 10px 0 15px;
    padding: 19px 16px 23px;
    position: relative;
  }

  /* ---------------------------------------------------------------- */
  /* Title / 
  /* ---------------------------------------------------------------- */
  h2 {
    font-family: $mainHeading;
    font-size: 18px;
    line-height: 19px;
    color: $black;
  }

  /* ---------------------------------------------------------------- */
  /* Subtitle / 
  /* ---------------------------------------------------------------- */
  h4 {
    font-size: 14px;
    line-height: 19px;
    font-family: $bodyHeading;
    color: $secondaryTitle;
    margin: 0 0 2px;
  }
  /* ---------------------------------------------------------------- */
  /* Company Name / Score / Thread Progress */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 12px;
    line-height: 17px;
    font-family: $bodyHeading;
    color: $greyDark;
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  div > ul {
    margin: 11px 0 0;
    padding-left: 24px;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Progress Track */
    /* ---------------------------------------------------------------- */
    &:before {
      content: "";
      background-color: $greyXLight;
      width: 6px;
      height: calc(100% - 16px);
      position: absolute;
      left: 5px;
      top: 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    border-color: black;
    + .progress-section {
      border-top-color: black;
    }
  }
}