@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.ChartItem{
  position: relative;
  padding: 15px 15px;
  h3 {
    font-family: $bodyHeading;
    font-size: 14px;
    color: $black;
    margin: 0 0 20px 0;
  }
  p {
    font-family: $regularText;
    font-size: 12px;
    color: $greyXDark;
    margin: 30px 0 0 0;
    &.table-data-message {
      margin-top: 12px;
    }
  }
}
.download-container{
  position: relative;
  display: flex;
  padding: 15px 15px 30px 15px;
  justify-content: flex-end;
}