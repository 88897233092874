@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress Section */
/* ---------------------------------------------------------------- */
.overall-section {
  background-color: $white;
  border-color: $greyLight;
  border-radius: 12px;
  border: 1px solid $greyFormBorder;
  display: flex;
  margin-bottom: 20px;

  > * {
    flex: 1 1 100%;
  }

  > div {
    padding: 20px 0;
    text-align: center;
    
    h4 {
      font-size: 24px;
      font-family: $mainHeading;
      color: $greyXXXDark;
    }

    span {
      display: block;
      font-size: 11px;
      color: $greyXDark;
    }

    + div {
      border-left: 1px solid $greyFormBorder;
    }
  }
}