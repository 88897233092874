@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question */
/* ---------------------------------------------------------------- */
.question {
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Status Bar */
  /* ---------------------------------------------------------------- */
  &:last-child > span {
    border-bottom-left-radius: 12px;
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  label + .info {
    margin-top: -4px;
  }
  /* ---------------------------------------------------------------- */
  /* Info */
  /* ---------------------------------------------------------------- */
  .info {
    color: $primaryTitle;
    font-size: 14px;
    line-height: 19px;
    font-family: $bodyText;
    margin: 0 0 15px;
    position: relative;
  }
  /* ---------------------------------------------------------------- */
  /* Error Text */
  /* ---------------------------------------------------------------- */
  [aria-live="polite"] {
    font-family: $bodyText;
    font-size: 14px;
    line-height: 19px;
    color: $error;
    margin: 8px 0 -5px;
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    .info {
      color: rgba($primaryTitle, 0.7);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    .info {
      color: rgba($primaryTitle, 0.7);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Diagnostic */
  /* ---------------------------------------------------------------- */
  &.diagnostic {
    display: flex;
    align-items: center;
    > label {
      margin: 0 20px 0 0;
    }
    ul {
      margin: 0;

      li {
        margin: 0 5px;
      }
    }
    .info {
      color: rgba($primaryTitle, 0.7);
    }
  }

  &.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    margin-top: 0;
    padding: 0 32px;
    border-top: 1px solid $greyFormBorder;
     > label {
       margin-bottom: 0;
     }
     > div {
       height: 100%;
       display: flex;
       align-items: center;
     }

     &:first-of-type {
      border-top: 1px solid $greyFormBorder!important;
     }

     + div:not(.row) {
      border-top: 1px solid $greyFormBorder;
     }

     &:nth-child(odd) {
       background-color: #F9FDFC;
     }
  }
}
