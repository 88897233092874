@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Button */
/* ---------------------------------------------------------------- */
.button {
  position: relative;
  display: inline-flex;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow: visible;
  @include fast-transition($props: "border-color, background-color");
  /* -------------------------------------------------------------- */
  /* Button - Icon */
  /* -------------------------------------------------------------- */
  svg {
    margin: 0 8px 0 14px;
    height: 16px;
    width: 16px;
    pointer-events: none;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    &:only-child {
      margin: 0 11px;
    }
    * {
      @include fast-transition($props: "fill, stroke");
    }
    + span {
      padding: 0 18px 0 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Focus Ring */
  /* -------------------------------------------------------------- */
  &::before {
    content: "";
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    z-index: 0;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 1px solid transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: content-box;
    display: block;
    border-radius: 10px;
    @include fast-transition($props: "border-color");
  }
  /* -------------------------------------------------------------- */
  /* Button - Text */
  /* -------------------------------------------------------------- */
  span {
    font-family: $bodyHeading;
    font-size: 14px;
    line-height: normal;
    white-space: nowrap;
    pointer-events: none;
    @include fast-transition($props: "color");
    position: relative;
    z-index: 1;
    padding: 0 24px;
    @at-root :focus#{&} {
      text-decoration: underline;
    }
  }
  /* -------------------------------------------------------------- */
  /* Size: Small */
  /* -------------------------------------------------------------- */
  &.small {
    height: 32px;
    border-width: 1px;
    span {
      padding: 0 14px;
    }
    svg {
      margin-left: 12px;
      height: 14px;
      width: 14px;
      &:only-child {
        margin: 0 7px;
      }
      + span {
        padding: 0 14px 0 0;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Style: Primary */
  /* -------------------------------------------------------------- */
  &.primary {
    background-color: $primary;
    border-color: $primary;
    span {
      color: $white;
      -webkit-font-smoothing: subpixel-antialiased;
    }
    svg * {
      fill: $white;
    }
    &:focus {
      background-color: $primaryDark;
      border-color: $primaryDark;
      &::before {
        border-color: $primaryDark;
      }
      span {
        text-decoration-color: $white;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryDark;
        border-color: $primaryDark;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryXDark;
        border-color: $primaryXDark;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Style: Secondary */
  /* -------------------------------------------------------------- */
  &.secondary {
    border-color: $primary;
    background-color: transparent;
    span {
      color: $primary;
    }
    svg * {
      fill: $primary;
    }
    &:focus {
      background-color: $primaryLight;
      &::before {
        border-color: $primary;
      }
      span {
        text-decoration-color: $primary;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryLight;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: transparent;
        border-color: $primaryDark;
        span {
          color: $primaryDark;
        }
        svg * {
          fill: $primaryDark;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Style: Negative */
  /* -------------------------------------------------------------- */
  &.negative {
    border-color: $errorLight;
    background-color: transparent;
    span {
      color: $error;
    }
    svg * {
      fill: $error;
    }
    &:focus {
      background-color: $errorXLight;
      &::before {
        border-color: $errorLight;
      }
      span {
        text-decoration-color: $error;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $errorXLight;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: transparent;
        border-color: $error;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Size: Inline */
  /* -------------------------------------------------------------- */
  &.inline {
    height: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    &::before {
      content: none;
    }
    span {
      padding: 0;
    }
    svg {
      margin-left: 0;
      height: 16px;
      width: 16px;
      &:only-child {
        margin: 0;
      }
    }
    &.primary {
      span {
        color: $primary;
        -webkit-font-smoothing: antialiased;
      }
      svg * {
        fill: $primary;
      }
      &:focus {
        background-color: transparent;
        span {
          color: $primaryDark;
          text-decoration-color: $primaryDark;
        }
        svg * {
          fill: $primaryDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: transparent;
          span {
            color: $primaryDark;
          }
          svg * {
            fill: $primaryDark;
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: transparent;
          span {
            color: $primaryXDark;
          }
          svg * {
            fill: $primaryXDark;
          }
        }
      }
    }
    &.secondary {
      span {
        color: $required;
      }
      svg * {
        fill: $required;
      }
      &:focus {
        background-color: transparent;
        span {
          text-decoration-color: $requiredDark;
          color: $requiredDark;
        }
        svg * {
          fill: $requiredDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: transparent;
          span {
            color: $requiredDark;
          }
          svg * {
            fill: $requiredDark;
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: transparent;
          span {
            color: $requiredXDark;
          }
          svg * {
            fill: $requiredXDark;
          }
        }
      }
    }
    &.negative {
      &:focus {
        background-color: transparent;
        span {
          text-decoration-color: $errorDark;
          color: $errorDark;
        }
        svg * {
          fill: $errorDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: transparent;
          span {
            color: $errorDark;
          }
          svg * {
            fill: $errorDark;
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: transparent;
          span {
            color: $errorXDark;
          }
          svg * {
            fill: $errorXDark;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Read Only */
  /* -------------------------------------------------------------- */
  &.read-only {
    pointer-events: none;
    &.primary {
      background-color: rgba($primary, 0.7);
      border-color: transparent;
    }
    &.primary.inline {
      background-color: transparent;
      span {
        color: rgba($primary, 0.7);
      }
      svg * {
        fill: rgba($primary, 0.7);
      }
    }
    &.secondary {
      border-color: rgba($primary, 0.7);
      span {
        color: rgba($primary, 0.7);
      }
      svg * {
        fill: rgba($primary, 0.7);
      }
    }
    &.secondary.inline {
      span {
        color: rgba($required, 0.7);
      }
      svg * {
        fill: rgba($required, 0.7);
      }
    }
    &.negative {
      border-color: rgba($errorLight, 0.7);
      span {
        color: rgba($error, 0.7);
      }
      svg * {
        fill: rgba($error, 0.7);
      }
    }
    &.negative.inline {
      span {
        color: rgba($error, 0.7);
      }
      svg * {
        fill: rgba($error, 0.7);
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  &.disabled {
    cursor: default;
    pointer-events: none;
    &::before {
      content: none;
    }
    &:focus {
      span {
        text-decoration: none;
      }
    }
    &.primary {
      background-color: $disabledLight;
      border-color: $disabledLight;
    }
    &.secondary {
      border-color: $disabledLight;
    }
    &.negative {
      border-color: $disabledLight;
    }
    &.inline {
      background-color: transparent;
      span {
        color: $disabled;
      }
      svg * {
        fill: $disabled;
      }
    }
    span {
      color: $disabled;
    }
    svg * {
      fill: $disabled;
    }
  }
  /* -------------------------------------------------------------- */
  /* Loading */
  /* -------------------------------------------------------------- */
  &.loading {
    > span, svg {
      color: transparent;
      opacity: 0;
    }
    > .spinner {
        opacity: 1;
        animation: rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px;
        width: 24px;
        height: 24px;
        fill: transparent;
    
        circle {
          fill: transparent;
          stroke: transparent;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }
      
    }

    &.primary {
     > svg circle {
      stroke: $white;
     } 
    }
    
    &.secondary {
      > svg circle {
        stroke: $primary;
        fill: transparent;
      } 
    }

    &.negative {
      > svg circle {
        stroke: $errorLight;
        fill: transparent;
      } 
    }
    
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }
}
