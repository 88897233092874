@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.descope-wrapper {
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px!important;
  height: 54px;

  > h2 {
    color: $secondaryTitle;
    font-family: $mainHeading;
    font-size: 16px;
    line-height: 19px;
    position: relative;
  }

}