@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.perspective {
  li {
    padding: 20px;
    background-color: $white;
    border-top: 1px solid $greyFormBorder;
    &.current {
      border: 1px solid $black;
    }
    &.complete {
      border-left: 4px solid $primary;
      p:last-child {
        color: $primary;
      }
    }
    &.inProgress {
      border-left: 4px solid $required;
      p:last-child {
        color: $required;
      }
    }
    h3 {
      margin: 0 0px 5px 0;
      font-size: 14px;
      line-height: 19px;
      font-family: $semiboldText;
      color: #282d3d;
      background-color: $greyXXLight;
      cursor: pointer;
    }
    p {
      margin: 0 0px 5px 0;
      font-size: 12px;
      line-height: 17px;
      font-family: $semiboldText;
      color: $greyDark;
      + p {
        margin-bottom: 0;
      }
    }
  }
}