@font-face {
  font-family: "OpenSans Regular";
  src: url("/fonts/OpenSans-Regular.eot");
  src: url("/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("/fonts/OpenSans-Regular.woff") format("woff"),
    url("/fonts/OpenSans-Regular.ttf") format("truetype"),
    url("/fonts/OpenSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans SemiBold";
  src: url("/fonts/OpenSans-SemiBold.eot");
  src: url("/fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("/fonts/OpenSans-SemiBold.woff") format("woff"),
    url("/fonts/OpenSans-SemiBold.ttf") format("truetype"),
    url("/fonts/OpenSans-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Semibold";
  src: url("/fonts/Montserrat-SemiBold.eot");
  src: url("/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("/fonts/Montserrat-SemiBold.ttf") format("truetype"),
    url("/fonts/Montserrat-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
