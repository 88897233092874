#ccc {
  #ccc-icon {
    width: 60px !important;
    height: 60px !important;
  }
  #ccc-title {
    font-family: "Montserrat Semibold", sans-serif;
    line-height: 27px;
  }
  .ccc-intro {
    margin: 5px 0 30px 0px;
    padding-right: 30px !important;
    font-family: "Montserrat Semibold", sans-serif;
    color: #767983 !important;
    font-size: 16px !important;
    line-height: 20px;
  }
  #ccc-button-holder {
    display: flex;
    justify-content: space-between;
    button {
      min-width: 140px;
      border-radius: 8px !important;
      touch-action: manipulation;
      overflow: visible;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      transition-property: border-color, background-color;
      span {
        position: relative;
        font-family: "OpenSans SemiBold", sans-serif;
        font-size: 14px;
        line-height: normal;
        white-space: nowrap;
        pointer-events: none;
        transition-duration: 0.1s;
        transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        transition-property: color;
        z-index: 1;
      }
    }
  }
  .ccc-accept-button {
    order: 2;
    height: 42px;
    background-color: #2ebfa5 !important;
    border-color: #2ebfa5 !important;
    &:hover {
      background-color: #01a789 !important;
      border-color: #01a789 !important;
    }
    span {
      color: #fff !important;
    }
  }
  .ccc-reject-button {
    order: 1;
    border-color: #f0bfca !important;
    background-color: transparent !important;
    &:hover {
      background-color: #fceaee !important;
      border-color: #f0bfca !important;
    }
    span {
      color: #ce2b4f !important;
    }
  }
  #ccc-necessary-title {
    font-family: "Montserrat Semibold", sans-serif;
    line-height: 19px;
    color: #282d3d !important;
  }
  #ccc-necessary-description {
    font-family: "OpenSans Regular", sans-serif;
    line-height: 19px;
  }
  #cc-panel hr {
    background-color: #a9a9a9 !important;
    height: 2px;
  }
  #ccc-optional-categories .checkbox-toggle {
    border: none !important;
    border-radius: 15px !important;
  }
  .checkbox-toggle--slider .checkbox-toggle-toggle {
    height: 13px !important;
    width: 13px !important;
    left: 4px !important;
    top: 4px !important;
  }
  .checkbox-toggle--slider input:checked ~ .checkbox-toggle-toggle {
    background-color: $primary !important;
    left: 23px !important;
  }
  .checkbox-toggle-on,
  .checkbox-toggle-off {
    display: none;
  }
  .checkbox-toggle-label {
    position: relative;
    height: 21px;
    width: 40px;
    border: 1px solid $greyLight;
    border-radius: 15px;
    cursor: pointer;
  }
  #ccc-icon svg #triangle path {
    fill: $primary;
  }
}
