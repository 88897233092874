@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.TableGroupWrapper{
  position: relative;

  &__title{
    position: relative;
    display: flex;
    align-items: center;
    font-family: $mainHeading;
    font-size: 14px;
    color: $greyXXXDark;
    span{
      display: inline-flex;
      svg {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  &__image-export{
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
    margin: 0 auto;
  }
  .TableDownloadContainer {
    padding: 10px 0;
  }
}