@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress Section Item */
/* ---------------------------------------------------------------- */
.progress-section-item {
  position: relative;
  margin: 0 0 16px;
  &:last-child {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Label / Button text */
  /* ---------------------------------------------------------------- */
  span {
    color: $greyXDark;
    font-family: $bodyHeading;
    text-align: left;
    display: inline-flex;
    @include fast-transition($props: "color");
    font-size: 12px;
    line-height: 17px;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button,
  div[role="button"] {
    cursor: pointer;
    width: 100%;
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      span {
        color: $primary;
        text-decoration: underline;
        text-decoration-color: $primary;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        span {
          color: $primary;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        span {
          color: $primaryDark;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Progress Indicator + Tick */
  /* ---------------------------------------------------------------- */
  &:before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    left: -24px;
    top: 1px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $white;
    border: 2px solid $greyXLight;
    z-index: 0;
    @include slow-transition(
      $mob: true,
      $props: "background-color, border-color"
    );
  }
  svg {
    width: 8px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: -20px;
    * {
      fill: $white;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    span {
      color: $primaryTitle;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Incomplete */
  /* ---------------------------------------------------------------- */
  &.incomplete {
    &::before {
      border-color: $required;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Complete */
  /* ---------------------------------------------------------------- */
  &.complete {
    &::before {
      border-color: $valid;
      background-color: $valid;
    }
  }
}