@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.review {
  height: 100%;
  display: flex;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__items {
    width: 100%;
    max-width: 650px;
  }

  &__item {
    background-color: $white;
    border: 1px solid $greyXLight;
    border-radius: 12px;
    font-size: 14px;
    color: #767983;

    + .review__item {
      margin-top: 20px;
    }

    &--row {
      .review {
        &__body {
          flex-direction: column;
        }
        &__item {
          &-list {
            + .review__item-list {
              margin: 24px 0 0;
            }

            li {
              padding: 4px 0;
            }
          }
        }
      }
    }

    &-header {
      display: flex;
      padding: 12px 32px;
      border-bottom: 1px solid $greyXLight;
      align-items: center;

      h2 {
        font-size: 18px;
        font-family: $bodyHeading;
        line-height: 22px;
        color: $black;
        margin-right: 24px;
      }

      button,
      div[role="button"] {
        position: relative;
        display: inline-flex;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $grey;
        box-sizing: border-box;
        cursor: pointer;
        vertical-align: top;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        overflow: visible;
        background-color: transparent;
        @include fast-transition($props: "border-color, background-color");
        span {
          font-family: $bodyHeading;
          color: $secondaryTitle;
          font-size: 14px;
          line-height: normal;
          white-space: nowrap;
          pointer-events: none;
          @include fast-transition($props: "color");
          position: relative;
          z-index: 1;
          padding: 0 16px;
        }
        &:focus {
          background-color: $disabledXLight;
          span {
            text-decoration: underline;
            text-decoration-color: $secondaryTitle;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            background-color: $disabledXLight;
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            background-color: darken($disabledXLight, 5%);
          }
        }
      }
    }

    &-list {
      + .review__item-list {
        margin-left: 48px;
      }

      h4 {
        margin-bottom: 8px;
        > strong {
          font-family: $mainHeading;
          color: $black
        }
      }
      ul {
        list-style: none;
      }

      li {
        padding: 2px 0;
      }

      span,
      p {
        strong {
          font-family: $bodyHeading;
          color: #282d3d;
        }
      }

      p + p {
        margin-top: 10px;
      }

      strong {
        span {
          color: $error;
        }
      }

      > p {
        color: $black;
        font-family: $bodyHeading;
        font-size: 12px;
        margin-top: 12px;
        span {
          color: $error;
        }
      }
    }
  }


  &__body {
    display: flex;
    padding: 16px 32px 32px;

    &-m-width {
      > div {
        max-width: 50%;
      }
    }
  }

  &__actions {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }
}


.checkIcon {
  position: relative;
  padding-left: 20px;
  &::before {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 10px;
    border: solid $primary;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}

.sidebar {
  max-width: 220px;
  width: 100%;
}

.hasDetails {
  width: 100%;
  > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
}