@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Main */
/* ---------------------------------------------------------------- */
.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 28px 40px 24px;
  max-width: 970px;
  box-sizing: border-box;
  > * {
    flex: 1 1 100%;
  }
  @media (max-width: 1120px) {
    max-width: 870px;
    padding: 28px 10px 24px 20px;
  }
}

/* ---------------------------------------------------------------- */
/* Navigation */
/* ---------------------------------------------------------------- */

.navigation {
  background-color: white;
  border: 1px solid $border-grey;
  min-width: 180px;
  width: 100%;
  max-width: 232px;
  box-sizing: border-box;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.5s;
  @media (max-width: 1120px) {
    min-width: 72px;
    max-width: 72px;
  }
  > .navLogo {
    opacity: 1;
    visibility: visible;
    width: 100%;
    max-width: 157px;
    height: auto;
    margin: 0 0 24px 20px;
    transition: width ease-in-out 0.5s, opacity ease-in-out 0.5s;
    @media (max-width: 1120px) {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      margin: 0;
    }
  }
  .navLogoSmall {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    transition: all ease-in-out 0.5s;
    max-width: 36px;
    @media (max-width: 1120px) {
      opacity: 1;
      visibility: visible;
      width: auto;
      height: auto;
      margin: 0 auto 24px;
    }
  }
  > span {
    display: block;
    margin: 0 0 8px 12px;
    font-size: 14px;
  }

  li {
    margin: 0 10px;
    + li {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid $grey;
    }
    a {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 12px;
      font-size: 14px;
      color: $black;
      font-family: $bodyHeading;
      letter-spacing: -0.4px;
      border-radius: 8px;
      transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: font-size ease-in-out 0.5s;
      @media (max-width: 1120px) {
        font-size: 0px;
        line-height: 0px;
      }
      &:hover {
        //border-top-color: #2FE1DD;
        border-bottom-color: #2fe1dd;
        background-color: rgba(#2fe1dd, 0.1);

        span {
          background-color: $primary;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      &.isActive {
        background-color: rgba(#2ebfa5, 0.1);

        span {
          background-color: $primary;
          svg {
            path {
              fill: $white;
            }
          }
        }
        // border-top: 1px solid #2FE1DD;
        // border-bottom: 1px solid #2FE1DD;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border-radius: 7px;
      //background-color: #2EBFA5;
      margin-right: 8px;
      border: 2px solid $primary;
      transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

      svg {
        width: 14px;
        path {
          fill: $primary;
        }
      }
    }
  }
  position: relative;
}

// .icon {
//   background-color: blue;
//  &.dashboard {
//    background-color: green;
//  }
// }

.logout-wrapper {
  position: absolute;
  bottom: 0;
  left: 10px;
  border-top: 1px solid $border-grey;
  padding: 16px 12px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  @media (max-width: 1120px) {
    text-align: center;
  }
  > button,
  div {
    svg {
      @media (max-width: 1120px) {
        margin-right: 0;
      }
    }
    svg * {
      // fill: $black !important;
    }
    span {
      // color: $black !important;
      transition: font-size ease-in-out 0.5s;
      @media (max-width: 1120px) {
        font-size: 0px;
      }
    }
  }
}

.myaxelos {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid $border-grey;
  padding: 16px 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: $bodyHeading;
  font-size: 14px;
  display: flex;
  color: $black;
  align-items: center;

  span {
    margin-left: 12px;
  }
}
