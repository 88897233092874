@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.ChartsContainer{
  position: relative;
  margin: 0 0 30px 0;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 12px;
  &.hasTab {
    border-top-left-radius: 0;
  }
}