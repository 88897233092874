@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.fieldset {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    flex-direction: row;

    span {
      margin-left: 10px;
    }
  }

  input {
    appearance: auto;
    -webkit-appearance: auto;
  }
}

.label {
  display: block;
  position: relative;
  cursor: pointer;
  font-family: $bodyHeading;
  color: $greyXXXDark;
  font-size: 14px;
  letter-spacing: -0.2px;
  padding: 9px 9px 9px 36px;
  max-width: 370px;
  margin: 0;
  z-index: 1;

  + .label {
    margin-top: 10px;
  }

  &:hover {
    input ~ span {
      background-color: $primaryLight;
      border-color: $primary;
      box-shadow: 0 0px 0 1px $primary;
    }
  }

  &.disabled {
    pointer-events: none;

    input:checked ~ span {
      border-color: #c4c5ca;
      background-color: rgba($primary, 0.02);
      box-shadow: 0 0px 0 1px #c4c5ca;

      &::before {
        border-color: #767983;
        background-color: #767983;
      }
    }
  }

  &.largetext {
    font-size: 12px;
    font-family: $bodyText;
    letter-spacing: initial;
    line-height: 17px;
    padding: 11px 9px 11px 48px;
    max-width: initial;

    &:hover {
      span {
        background-color: transparent;
        border-color: $grey;
        box-shadow: none;
      }
    }

    input:checked ~ span {
      background-color: transparent;
      box-shadow: none;
      border-color: $greyXLight;
    }

    input:focus ~ span {
      border-color: $grey;
     }

    span {
      &::before {
        left: 16px;
        top: 13px;
      }

      &::after {
        top: 16px;
        left: 22px;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span {
      background-color: $primaryLight;
      border-color: $primary;
      box-shadow: 0 0px 0 1px $primary;

      &::before {
        border-color: $primary;
        background-color: $primary;
      }

      &::after {
        display: block;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid $greyXLight;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 9px;
      height: 18px;
      width: 18px;
      border-radius: 2px;
      border: 1px solid $greyXLight;
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 15px;
      top: 11px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
