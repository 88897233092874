@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.diagnostic {
  border: 1px solid #E1E2E4;
  border-radius: 4px;
  padding: 0;
  margin-top: 16px;
 
  input {
    appearance: auto;
  }

  > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    padding: 11px 24px;
    font-family: $bodyHeading;
    color: black;
    outline: none;
    @media(max-width: 963px){
      padding: 11px 15px;
    }
    > svg {
      cursor: pointer;
      transition: transform 0.2s;
    }

    > span {
      display: flex;
      align-items: center;

      > div {
        height: 18px;
        width: 18px;
        min-width: 18px;
        border-radius: 50%;
        border: 2px solid #767983; 
        color: #767983; 
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
      }
    }
  }

  &.is-open {
    > h3 > svg {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: $bodyText;
    min-width: 400px;
  }

  > div {
    padding: 0 24px;
    @media(max-width: 963px){
      padding: 0 15px;
    }
  }
  div + div {
    margin-top: 8px;
  }
}


.tag {
  background-color: $greyXLight;
  border-radius: 12px;
  color: $greyXXXDark;
  padding: 2px 10px;
  position: absolute;
  top: 20px;
  font-size: 12px;
}

.itil {
    > fieldset {
      > div:not(.heading) {
        padding-top: 60px!important;
      }
    }

    &.maturity {
      > fieldset {
        > div:first-of-type {
          padding-top: 30px!important;
          >label {
            margin-bottom: 20px;
          }
          > div {
            > ul li {
              width: 100%;
              > button, .button {
                padding: 12px 9px 20px;
                span {
                  line-height: 1.5;
                  p {
                    font-family: $bodyText;
                    strong {
                      font-family: $bodyHeading;
                      display: block;
                    }
                    
                  }
                }
              }
            }
          }
        }
      }
    }
}

.heading {
  padding-top: 40px!important;
  h3 {
    font-family: $bodyHeading;
    color: $black;
  }
}

.capability {
  position: relative;

  &.disabled {
    &::before {
      background-color: $disabled;
    }
  }

  &::before{
    content: "";
    width: 5px;
    background-color: $primary;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  > label, > p {
    color: $greyXXXDark;
    font-family: $semiboldText;
  }

  > p {
    margin: 20px 0 10px;
  }

  > div {
    border: 1px solid $greyFormBorder;
    padding: 20px;
    color: $greyXXXDark;
    border-radius: 4px;
  }
}