@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.heatmap {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 16px 16px 16px 48px;

  h3 {
    font-family: $bodyHeading;
    font-size: 14px;
    color: $greyXXXDark;
    margin: 0 0 20px -32px;
  }

  .heatmapWrapper {
    display: flex;
    max-width: 100%;
    border-left: 1px solid $greyXXDark;
    border-bottom: 1px solid $greyXXDark;
    margin-bottom: 5px;
    position: relative;
  }

  .heatmapLevels {
    position: absolute;
    top: 5px;
    left: -12px;
    height: calc(100% - 10px);
    > span {
      transform: rotate(-90deg) translateY(-50%);
      position: absolute;
      left: -24px;
      top: 42%;
      color: $greyXXXDark;
    }
    > ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      > li {
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;
        color: $greyXXXDark;
      }
    }
  }

  .heatmapBars {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    width: 100%;
    > ul {
      margin: 0 5px;
      text-align: center;
      flex: 1 1 100%;

      > li {
        width: 100%;
        height: 20px;
        border-radius: 4px;
        background-color: $white;
        margin: 5px 0;

        &.fully {
          background-color: rgba($primary, .7);
        }
        &.partially {
          background-color: rgba($required, .7);
        }
        &.rare {
          background-color: rgba($greyXLight, .7);
        }
      }
      > span {
        font-size: 12px;
        margin-bottom: 2px;
        display: block;
      }
    }
  }

  .heatmapLabels {
    > ul {
      display: flex;

      > * {
        flex: 1 1 100%;
      }
      
      > li {
        font-size: 9px;
        text-align: center;
        line-height: 1.3;
        display: block;
        color: $greyXXXDark;
      }
    }
  }

  .heatmapLegend {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 0;

    > div {
      display: flex;
      align-items: center;
      font-size: 10px;
      color: $greyXXXDark;

      
      &:first-of-type {
        span {
          background-color: rgba($primary, .7);
        }
      }
      &:nth-of-type(2) {
        span {
          background-color: rgba($required, .7);
        }
      }
    }

    span {
      display: block;
      width: 25px;
      height: 18px;
      border-radius: 4px;
      margin-right: 8px;
      background-color: rgba($greyXLight, .7);
    }
    
  }
}

.legend {
  padding: 0 15px;
  font-size: 12px;
  display: block;
}

.download-container{
  position: relative;
  display: flex;
  padding: 15px 15px 30px 15px;
  justify-content: flex-end;
}