@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";

.note {
  background-color: rgba(#FFC400, 0.05);
  border: 2px dashed #FFC400;
  display: block;
  border-radius: 12px;
  max-width: 220px;
  padding: 18px;
  box-sizing: border-box;
  margin: 0;

  &:only-child {
    margin-top: 51px;
  }

  &__wrapper {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity, height .2s;

    &.isActive {
      height: 210px;
      opacity: 1;
    }
  }

  > svg {
    height: 31px;
    width: 27px;
    margin-bottom: 12px;
  }

  h3 {
    font-family: $mainHeading;
    color: $black;
    font-size: 18px;

    + p {
      margin-top: 16px;
    }
  }
  p {
    color: rgba($black, .7);
    font-size: 14px;
    line-height: 19px;
    display: block;

    > a {
      position: relative;
      color: black;
      text-underline-position: under;
      text-decoration: underline;
      -webkit-text-stroke: black;
      
      // &:before {
      //   content: "";
      //   width: 100%;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   background-color: black;
      //   display: block;
      //   height: 1px;
      // }
    }

    + a {
      margin-top: 20px;
    }
    strong {
      font-family: $semiboldText;
    }
    + p, + button {
      margin-top: 14px;
    }
  }
}

