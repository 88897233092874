@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.extra_info {
  border: 1px solid #2EBFA5;
  padding: 20px;
  border-radius: 8px;

  > h4 {
    color: #282D3D;
    font-size: 14px;
    font-family: $bodyHeading;
    margin-bottom: 10px;
  }

  > p {
    opacity: .8;
  }
}
