@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Sidebar */
/* ---------------------------------------------------------------- */
.sidebar {
  display: flex;
  flex-direction: column;
  max-width: 220px;
  align-self: flex-start;
  margin-left: 20px;

  &.large{
    max-width: 310px;
  }
  
  > div + div, > div + section {
    margin-top: 24px;
  }
}