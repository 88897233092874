@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* DropOutMenu */
/* ---------------------------------------------------------------- */
.DropOutMenu {
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Border deviation */
  /* ---------------------------------------------------------------- */
  > span {
    display: none;
    border-left: 1px solid rgba($grey, 0.5);
    border-top: 1px solid rgba($grey, 0.5);
    border-radius: 5px;
    text-align: center;
    position: absolute;
    background:white;
    width: 12px;
    height: 12px;
    top: 26px;
    transform: rotate(45deg);
    right: 3px;
    z-index: 3;
    &.openMenu{
      display: block;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button {
    cursor: pointer;
    padding: 0 6px 0 24px;
    span {
      width: 5px;
      height: 5px;
      margin: 2px 0;
      background-color: $greyXDark;
      display: block;
      border-radius: 50%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Floating Menu */
  /* ---------------------------------------------------------------- */
  > div {
    border: 1px solid rgba($grey, 0.5);
    border-radius: 10px;
    text-align: center;
    position: absolute;
    background:white;
    display: none;
    right: -100%;
    top: calc(100% + 10px);
    overflow: hidden;
    z-index: 2;
    white-space: nowrap;
    @include fast-transition($props: "background-color");
    &.openMenu{
      display: block;
    }
    /* ---------------------------------------------------------------- */
    /* Menu Item */
    /* ---------------------------------------------------------------- */
    .DropOutItem{
      padding: 8px 22px;
      color: black;
      + .DropOutItem {
        border-top: 1px solid rgba($grey, 0.5);
      }

      &:hover{
        background: $primaryLight;
      }
    }
  }
}