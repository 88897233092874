@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.ChartsHeader{
  position: relative;
  padding: 15px;
  border-bottom: 1px solid #dddddd;
  h3{
    font-family: $bodyHeading;
    font-size: 14px;
    color: $black;
    margin-bottom: 15px;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    max-width: 528px;
    li {
      margin-bottom: 5px;
      width: 50%;
      font-size: 14px;
      color: #6A6E77;
      line-height: 18px;
      &:nth-child(3),
      &:nth-child(4){
        margin-bottom: 0;
      }
      span{
        display: block;
        font-family: $bodyHeading;
        color: $greyXXXDark;
      }
    }
  }
}