@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Select */
/* ---------------------------------------------------------------- */
.select {
  position: relative;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Select - Select */
  /* ---------------------------------------------------------------- */
  select {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 42px;
    padding: 0 44px 0 16px;
    border-radius: 8px;
    font-family: $bodyText;
    border: 1px solid $greyXLight;
    background-color: $white;
    color: $secondaryTitle;
    font-size: 14px;
    line-height: normal;
    font-weight: normal;
    box-sizing: border-box;
    outline: none;
    resize: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    filter: none;
    @include no-user-select;
    @include fast-transition($props: "color, border-color");
    option {
      color: $secondaryTitle;
    }
    &::-ms-expand {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $white;
      border-color: $grey;
      @include user-select;
      &::-ms-value {
        background-color: $white;
        color: $secondaryTitle;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $grey;
        &:focus {
          border-color: $grey;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Select - Icon */
  /* ---------------------------------------------------------------- */
  svg {
    height: 18px;
    width: 12px;
    flex-shrink: 0;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    * {
      fill: $greyXDark;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Placeholder */
  /* ---------------------------------------------------------------- */
  &.placeholder {
    select {
      color: $greyXDark;
      @include no-user-select;
      &::-ms-value {
        color: $greyXDark;
      }
    }
    &.invalid {
      select {
        color: $error;
        &::-ms-value {
          color: $error;
        }
        &:focus {
          color: $error;
          &::-ms-value {
            color: $error;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    display: block;
    select {
      color: $error;
      border-color: $error;
      &:focus {
        border-color: $error;
        color: $errorDark;
        &::-ms-value {
          color: $errorDark;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $error;
          border-color: $error;
          &:focus {
            color: $errorDark;
            &::-ms-value {
              color: $errorDark;
            }
          }
        }
      }
    }
    svg * {
      fill: $error;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    select {
      pointer-events: none;
      color: rgba($secondaryTitle, 0.7);
      border-color: rgba($greyXLight, 0.7);
    }
    &.placeholder {
      select {
        color: rgba($greyXDark, 0.7);
      }
    }
    svg {
      * {
        fill: rgba($grey, 0.7);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    select {
      pointer-events: none;
      opacity: 1;
      color: rgba($secondaryTitle, 0.7);
      border-color: rgba($greyXLight, 0.7);
    }
    &.placeholder {
      select {
        color: rgba($greyXDark, 0.7);
      }
    }
    svg {
      * {
        fill: rgba($grey, 0.7);
      }
    }
  }
}
