/* --------------------------------------------------------------------- */
/* Typography */
/* --------------------------------------------------------------------- */

// Heading
$semiboldHeading: "Montserrat Semibold", sans-serif;

// Text
$semiboldText: "OpenSans SemiBold", sans-serif;
$regularText: "OpenSans Regular", sans-serif;

/* --------------------------------------------------------------------- */
/* Colours */
/* --------------------------------------------------------------------- */

// Primary Colours
$primaryXDark: #058b73;
$primaryDark: #01a789;
$primary: #2ebfa5;
$primaryLight: #e9f9f6;
$primaryPastel: #f9fdfc;

// Black and White
$black: #000000;
$white: #ffffff;

// Shades
$greyXXXDark: #282d3d;
$greyXXDark: #707070;
$greyXDark: #767983;
$greyDark: #9d9d9d;
$grey: #c4c5ca;
$greyLight: #d9dee0;
$greyXLight: #dddddd;
$greyXXLight: #fcfcfd;
$greyXXXLight: #f9f9f9;
$greyFormBorder: #E1E2E4;

// Valid Colours
$valid: $primary;

// Required Colours
$requiredXDark: #cd9d00;
$requiredDark: #e7b100;
$required: #ffc401;
$requiredLight: #fffcf2;

// Error Colours
$errorXDark: #8f1e37;
$errorDark: #ae2443;
$error: #ce2b4f;
$errorLight: #f0bfca;
$errorXLight: #fceaee;

// Disabled
$disabledXXDark: #4d4d4d;
$disabledXDark: #575757;
$disabledDark: #71757f;
$disabled: #95989f;
$disabledLight: #dfdfe2;
$disabledXLight: #eeeeee;
$disabledXXLight: #e7e7e7;

// Borders
$border-grey: #dedede;

// Disabled/Read Only
$inputBorder: #C4C5CA;
$checkBox: #767983;

/* --------------------------------------------------------------------- */
/* Config */
/* --------------------------------------------------------------------- */

// Typography Colours
$primaryTitle: $black;
$secondaryTitle: $greyXXXDark;
$text: $greyXXDark;

// Typography Font Families
$mainHeading: $semiboldHeading;
$bodyHeading: $semiboldText;
$bodyText: $regularText;
