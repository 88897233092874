body {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

button {
  display: block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

*[role="button"] {
  &:focus {
    outline: none;
  }
}

legend {
  padding: 0;
  margin: 0;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
  min-width: 0;
}

input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
}

iframe {
  border: none;
}

strong {
  font-weight: normal;
}
