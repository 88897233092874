@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";

.tableWrapper {
  border: none;
  padding: 0;

  > table {
    border-collapse: collapse;
    width: 100%;
    min-width: 300px;
    border-color: transparent;
    margin-bottom: 20px;
    max-width: 100%;
    + table {
      margin-bottom: 32px;
    }

    > thead {
      
      > tr th {
        padding-top: 16px;
        padding-bottom: 16px;
        color: $black;
        border-bottom: 1px solid $greyFormBorder;

        &:first-of-type {
          font-size: 18px;
          font-family: $mainHeading;
        }
      }

      
    }

    &.itil {
      > tbody {
        > tr {
          &.bodyHead {
            th {color: $greyXXXDark}
          }
        }
      }

      th {
        &:nth-child(3) {
          padding-right: 16px;
        }
        &:last-of-type {
          padding-right: 20px;
          padding-left: 10px;
        }
      }
    }
  
  > tbody {
    > tr {
        border-bottom: 1px solid $greyFormBorder;
        border-top: 1px solid transparent;
        color: $black;
        &:nth-child(odd):not(.bodyHead):not(.perspectiveHead) {
          background-color: #f9fdfc;
        }

        &.answered {
          > td {
            &:first-of-type{
              position: relative;
              //border-left:5px solid $primary;
              &::before {
                content: "";
                position: absolute;
                top:1px;
                left: 0;
                width: 5px;
                height: calc(100% - 1px);
                display: block;
                background-color: $primary;
              }
            }
          }
        }

        &.notAnswered {
          > td {
            &:first-of-type{
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top:1px;
                left: 0;
                width: 5px;
                height: calc(100% - 1px);
                display: block;
                background-color: $grey;
              }
            }
          }
        }

        &.warning {
          > td {
            &:first-of-type{
              position: relative;
              //border-left:5px solid $primary;
              &::before {
                content: "";
                position: absolute;
                top:1px;
                bottom: 0px;
                left: 0;
                width: 5px;
                //height: calc(100% - 3px);
                display: block;
                background-color: $required;
              }
            }
          }
        }

        &.perspectiveHead {
          border-bottom: none;
          > th {
            color: $black;
            font-size: 14px;
            font-family: $mainHeading;
            font-weight: normal;
            padding-top: 24px;
                
            &:nth-child(2){
              font-family: $bodyText;
              max-width: 120px;
              span {
                display: block;
              }
            }
          }
        }

        &.bodyHead {
          > th {
            color: #C4C5CA;
            font-family: $bodyHeading;
            font-size: 14px;
            letter-spacing: -.2px;
            border-bottom: 1px solid $greyFormBorder;
          }
        }

        > td {
          &:first-of-type {
            letter-spacing: -.2px;
            font-family: $bodyHeading;
          }
        }
      }
    }
  }

  td,
  th {
    border: none;
    text-align: left;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: normal;
    padding: 9px 0;

    &:nth-child(1) {
      padding-left: 32px;
    }
    &:nth-child(3) {
      padding-right: 32px;
    }
  }
}

.pageActions {
  margin-top: 20px;
  > button {
    display: block;
    margin-left: auto;
  }
}

.maturityScore {
  font-family: $bodyHeading;
  color: $black;
  text-align: right;
  margin-bottom: 20px;
  padding-right: 32px;
}