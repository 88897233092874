@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.diagnostic {
  border: 1px solid #E1E2E4;
  border-radius: 4px;
  padding: 0;
  margin-top: 16px;
 
  input {
    appearance: auto;
  }

  > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    padding: 11px 24px;
    font-family: $bodyHeading;
    color: black;
    outline: none;
    @media(max-width: 963px){
      padding: 11px 15px;
    }
    > svg {
      cursor: pointer;
      transition: transform 0.2s;
    }

    > span {
      display: flex;
      align-items: center;

      > div {
        height: 18px;
        width: 18px;
        min-width: 18px;
        border-radius: 50%;
        border: 2px solid #767983; 
        color: #767983; 
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
      }
    }
  }

  &.is-open {
    > h3 > svg {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: $bodyText;
    min-width: 400px;
  }

  > div {
    padding: 0 24px;
    @media(max-width: 963px){
      padding: 0 15px;
    }
  }
  div + div {
    margin-top: 8px;
  }
}
