@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.button {
  position: relative;
  height: 44px;
  padding: 0 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border-color: $greyXLight;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  @include slow-transition(
    $mob: true,
    $props: "border-color, background-color"
  );
  /* -------------------------------------------------------------- */
  /* Button - Focus Ring */
  /* -------------------------------------------------------------- */
  &::before {
    content: "";
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    z-index: 0;
    position: absolute;
    top: -5px;
    left: -5px;
    border: 1px solid transparent;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    box-sizing: content-box;
    display: block;
    border-radius: 6px;
    pointer-events: none;
    @include fast-transition($props: "border-color");
  }
  /* -------------------------------------------------------------- */
  /* Button - Text */
  /* -------------------------------------------------------------- */
  span {
    font-family: $semiboldText;
    color: $secondaryTitle;
    font-size: 14px;
    line-height: normal;
    white-space: nowrap;
    pointer-events: none;
    @include slow-transition($mob: true, $props: "color");
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: center;
    @at-root :focus#{&} {
      text-decoration: underline;
    }
    /* ---------------------------------------------------------------- */
    /* Radio / Checkbox Icon */
    /* ---------------------------------------------------------------- */
    &::before {
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: $greyXLight;
      margin: 0 9px 0 0;
      flex-shrink: 0;
      pointer-events: none;
      @include slow-transition($mob: true, $props: "border-color");
    }
    /* ---------------------------------------------------------------- */
    /* Radio / Checkbox Icon - Selected */
    /* ---------------------------------------------------------------- */
    &::after {
      flex-shrink: 0;
      opacity: 0;
      position: absolute;
      background-color: transparent;
      pointer-events: none;
      @include slow-transition($mob: true, $props: "background-color, opacity");
    }
  }
  /* ---------------------------------------------------------------- */
  /* Radio  */
  /* ---------------------------------------------------------------- */
  &.radio {
    width: 100%;
    padding-left: 14px;
    @media (max-width: 1120px) {
      padding-left: 10px;
      padding-right: 19px;
    }
    span {
      /* ---------------------------------------------------------------- */
      /* Radio Circle */
      /* ---------------------------------------------------------------- */
      &::before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      /* ---------------------------------------------------------------- */
      /* Radio Circle Inner */
      /* ---------------------------------------------------------------- */
      &::after {
        content: "";
        border-radius: 50%;
        width: 9px;
        height: 9px;
        left: 3px;
        top: 5px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Checkbox  */
  /* ---------------------------------------------------------------- */
  &.checkbox {
    width: 100%;
    height: 54px;
    padding: 0 18px;
    span {
      /* ---------------------------------------------------------------- */
      /* Radio Circle */
      /* ---------------------------------------------------------------- */
      &::before {
        content: "";
        width: 18px;
        height: 18px;
      }
      /* ---------------------------------------------------------------- */
      /* Radio Circle Inner */
      /* ---------------------------------------------------------------- */
      &::after {
        content: "";
        width: 16px;
        height: 16px;
        top: 1px;
        left: 1px;
        background-image: url("/images/tick.svg");
        background-size: 11px 9px;
        background-repeat: no-repeat;
        background-position: 2px 4px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Switch  */
  /* ---------------------------------------------------------------- */
  &.switch {
    min-width: 80px;
    justify-content: center;
    span {
      justify-content: center;
    }
  }
  /* ---------------------------------------------------------------- */
  /* True / False */
  /* ---------------------------------------------------------------- */
  &.true,
  &.false {
    height: 18px;
    width: 18px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $grey;
    svg path {
      fill: $grey;
      @include slow-transition($mob: true, $props: "fill");
    }
    &::before {
      border-radius: 50%;
    }
  }
  &.true {
    svg {
      width: 9px;
      height: 7px;
    }
  }
  &.false {
    svg {
      width: 2px;
      height: 9px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    background-color: $primaryLight;
    border-color: $primary;
    span {
      text-decoration: underline;
      text-decoration-color: $text;
    }
    &::before {
      border-color: $primary;
    }
    &.true {
      svg path {
        fill: $primary;
      }
    }
    &.false {
      background-color: $requiredLight;
      border-color: $required;
      &::before {
        border-color: $required;
      }
      svg path {
        fill: $required;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:not(.selected):hover {
      background-color: $primaryLight;
      border-color: $primary;
      &.true {
        svg path {
          fill: $primary;
        }
      }
      &.false {
        background-color: $requiredLight;
        border-color: $required;
        svg path {
          fill: $required;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    border-color: $error;
    span {
      color: $error;
      &::before {
        border-color: $error;
      }
    }
    &:focus {
      border-color: $error;
      background-color: $errorXLight;
      &::before {
        border-color: $error;
      }
      span {
        text-decoration-color: $error;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $error;
        background-color: $errorXLight;
      }
    }
    &.true,
    &.false {
      svg path {
        fill: $error;
      }
    }
    &.true {
      &:focus {
        svg path {
          fill: $error;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          svg path {
            fill: $error;
          }
        }
      }
    }
    &.false {
      &:focus {
        border-color: $error;
        background-color: $errorXLight;
        svg path {
          fill: $error;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $error;
          background-color: $errorXLight;
          svg path {
            fill: $error;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    background-color: $primaryLight;
    border-color: $primary;
    span {
      &::before {
        border-color: $primary;
      }
      &::after {
        opacity: 1;
        background-color: $primary;
      }
    }
    &.true {
      background-color: $primary;
      svg path {
        fill: $white;
      }
    }
    &.false {
      background-color: $required;
      border-color: $required;
      svg path {
        fill: $white;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    pointer-events: none;
    border-color: rgba($greyXLight, 0.7);
    span {
      color: rgba($secondaryTitle, 0.7);
      &::before {
        border-color: rgba($greyXLight, 0.7);
      }
    }
    &.true,
    &.false {
      svg path {
        fill: rgba($grey, 0.7);
      }
    }
    &.selected {
      background-color: rgba($primaryLight, 0.7);
      border-color: rgba($primary, 0.7);
      span {
        &::before {
          border-color: rgba($primary, 0.7);
        }
        &::after {
          background-color: rgba($primary, 0.7);
        }
      }
      &.true {
        border-color: transparent;
        background-color: rgba($primary, 0.7);
        svg path {
          fill: $white;
        }
      }
      &.false {
        border-color: transparent;
        background-color: rgba($required, 0.7);
        svg path {
          fill: $white;
        }
      }
    }
    &.checkbox{
      background-color: rgba(46, 191, 165, 0.025);
      border-color: $inputBorder;
      span{
        &:before {
          border-color: $checkBox;
        }
        &:after {
          background-color: $checkBox;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    pointer-events: none;
    border-color: rgba($greyXLight, 0.7);
    span {
      color: rgba($secondaryTitle, 0.7);
      &::before {
        border-color: rgba($greyXLight, 0.7);
      }
    }
  }
  
}
