@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.itilWrapper {
  fieldset {
    padding-left: 0;
    padding-right: 0;
  }
  legend {
    padding-left: 32px;
    padding-right: 32px;

    + .rowHeader {
      border-top: 1px solid $greyFormBorder!important;
    }
  }

}

.rowHeader {
  padding: 48px 32px 24px;
 //border-bottom: 1px solid $greyFormBorder;
 margin-top: 0!important;

  h3 {
    font-family: $mainHeading;
    color: $greyXXXDark;
    font-size: 16px;
    margin-bottom: 10px;
  }
  p {
    font-family: $bodyHeading;
    color: $greyXXXDark;
    margin-bottom: 5px;
  }
  span {
    color: $greyXDark;
  }

}

.warningBox {
  padding: 48px 32px 24px;
  margin-top: 0!important;
  h3 {
    font-family: $mainHeading;
    color: $greyXXXDark;
    font-size: 16px;
    margin-bottom: 20px;
  }
  p {
    font-family: $bodyHeading;
    color: $greyXXXDark;
    margin-bottom: 5px;
  }
  span {
    color: $greyXDark;
  }

  div {
    color: $greyXXXDark;
    font-family: $bodyHeading;
    font-size: 12px;
    margin-top: 20px;
    span {
      color: $error
    }
  }

  ul {
    margin-top: 24px;
    li {
      padding: 10px 0 10px 24px;
      position: relative;
      font-size: 14px;
      color: $greyXXXDark;
      font-family: $bodyHeading;
      span {
        color: $error
      }
      &::before {
        content: "";
        position: absolute;
        left: 6px;
        top: 10px;
        width: 4px;
        height: 10px;
        border: solid $primary;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.fakeItem {
  margin-top: 0!important;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    margin-top: 0;
    padding: 0 32px;
    background-color: #F9FDFC;
    border-bottom: 1px solid $greyFormBorder; 
  
    > p {
      max-width: 400px;
      color: $primaryTitle;
      font-size: 14px;
      line-height: 19px;
      font-family: $bodyHeading;
      margin: 0;
      position: relative;
      padding-left: 24px;
      span {
        color: $error;
      }
      &::before {
        content: "";
        position: absolute;
        left: 4px;
        top: 2px;
        width: 4px;
        height: 10px;
        border: solid $primary;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
    > span {
      width: 242px;
      text-align: left;
      font-family: $bodyHeading;
      color: $primaryTitle;
    }
  }

  > p {
    padding: 10px 32px 20px;
    color: $black;
    font-size: 12px;
    span {
      color: $error;
    }
  }
  
}