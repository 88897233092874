@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.organization-details {
  width: 100%;

  > h4 {
    font-family: $mainHeading;
    color: $greyXDark;
    font-size: 14px;
    margin-bottom: 8px;
  }

  > p {
    color: $greyDark;
    font-size: 12px;
    font-family: $bodyText;
    //display: flex;
    strong {
      color: $black;
      font-family: $bodyHeading;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      max-width: 170px;
      //display: inline-block;
      margin-left: 5px;
    }
  }
}