@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Section */
/* ---------------------------------------------------------------- */
.section {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  position: relative;

  &.small {
    max-width: 570px;
  }

  &.large {
    max-width: 1400px;
  }
}
