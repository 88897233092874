html {
  line-height: 1.25;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  color: $text;
  font-size: 14px; // TODO Double check
  font-family: $bodyText;
}

body {
  width: 100%;
  background-color: $greyXXXLight;
  @include no-user-select;
  background-color: #f9f9f9;
  min-width: 942px;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
