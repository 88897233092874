@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.fieldset {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 14px;
      color: $greyXXXDark;
      font-family: $bodyHeading;
      margin-bottom: 12px;
    }

    &.inline {
      flex-direction: row;
      justify-content: space-between;
      h4 {
        font-size: 16px;
      }
      .label {
        padding: 0;
        max-width: 38px;
        span {
          background-color: transparent;
          border: transparent;
          &::before {
            left: 4px;
            top: 6px;
            background-color: $primary;
            border-color: transparent ;
          }
          &::after {
            width: 36px;
            top: 2px;
            left: 0px;
          }
        }
        input {
          &:checked + span {
            &::before {
              background-color: $white;
              border-color:$primary;
              left: 0;
            }
            &::after {
              background-color: $primary;
              border-color:$primary;
            }
          }
        }
      }
    }
}

.label {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: auto;
  max-width: 308px;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
  padding: 16px;

  p {
    position: relative;
    z-index: 2;
    font-size: 12px;
    line-height: 17px;
    color: $greyXDark;
    + p {
      margin-top: 5px;
    }
  }

  > div {
    max-width: 220px;
    margin-left: auto;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: $primaryLight;
      border-color: $primary;

      &:before {
        background-color: $primary;
        border-color: $primary;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
      &::after {
        border-color: $primary;
      }
    }

  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    border: 1px solid $greyLight;
    border-radius: 8px;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 1;
    margin:0;

    &::before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 20px;
      top: 25px;
      background-color: $grey;
      border: 1px solid $greyLight;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
      z-index: 1;
    }
    &::after {
      position: absolute;
      content: "";
      height: 21px;
      width: 40px;
      left: 16px;
      top: 21px;
      background-color: white;
      border: 1px solid $greyLight;
      border-radius: 15px;
    }



  }
}
