@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Fieldset */
/* ---------------------------------------------------------------- */
.fieldset {
  position: relative;
  background-color: $white;
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 650px;
  + fieldset {
    margin-top: 32px;
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset - Legend */
  /* ---------------------------------------------------------------- */
  > legend {
    width: 100%;
    padding: 16px 0;
    border-style: solid;
    border-width: 1px 1px 0;
    border-color: $greyFormBorder;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-sizing: border-box;
    float: left;
    + * {
      clear: both;
      border-top: none;
    }
    + .fieldset-column {
      border-radius: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Fieldset - Legend - Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $secondaryTitle;
      font-family: $mainHeading;
      font-size: 16px;
      line-height: 19px;
      // text-transform: capitalize;
      position: relative;
      padding: 0 32px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset - Rows */
  /* ---------------------------------------------------------------- */
  &:not(.assessment-setup) > div{
    background-color: $white;
    border-style: solid;
    border-width: 1px 1px 0;
    border-color: $greyFormBorder;
    padding: 24px 32px;
    position: relative;
    &:nth-child(odd) {
      background-color: $greyXXLight;
    }
    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom: 1px solid $greyFormBorder;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons - Debug */
  /* ---------------------------------------------------------------- */
  > div {
    > div[role="button"],
    > button,
    > a {
      margin: 0 10px 10px 0;
    }
  }
}


/* ---------------------------------------------------------------- */
/* Fieldset Column */
/* ---------------------------------------------------------------- */

.fieldset-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:only-of-type {
    border-radius: 12px 12px 0 0;
  }
  
  > div {
    box-sizing: border-box;
    width: calc(50% - 16px);
    border: none;
    margin-bottom: 24px;
  }

  > div:last-of-type {
    width: 100%;
    margin-bottom: 12px;
  }
}

/* ---------------------------------------------------------------- */
/* Fieldset Column */
/* ---------------------------------------------------------------- */
.assessment-setup {
  border: 1px solid $greyFormBorder;
  padding: 32px;

  @media (max-width: 1120px) {
    padding: 28px 24px;
  }

  > legend {
    border: none;
    padding: 0;
    margin: 0 0 16px;
    h2 {
      padding: 0;
    }
  }

  > div {
    + div {
      margin-top: 18px;
    }
  }
}
