@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress Section */
/* ---------------------------------------------------------------- */
.progress-section {
  background-color: $white;
  border-style: solid;
  border-color: $greyLight;
  border-width: 1px 1px 0;
  padding: 19px 16px 23px;
  position: relative;
  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-width: 1px;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button,
  > div[role="button"] {
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    cursor: pointer;
    padding: 19px 16px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &::after {
      content: "";
      height: 16px;
      width: 16px;
      background-color: transparent;
      border: 1px solid $primary;
      position: absolute;
      right: 12px;
      top: 50%;
      margin-top: -9px;
      border-radius: 50%;
      opacity: 0;
      @include fast-transition($mob: true, $props: "opacity");
    }
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    > svg {
      width: 10px;
      height: 6px;
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: -3px;
      * {
        fill: $text;
        @include fast-transition($mob: true, $props: "fill");
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      &::after {
        opacity: 1;
      }
      svg * {
        fill: $primary;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        svg * {
          fill: $primary;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        svg * {
          fill: $primaryDark;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title / Perspective */
  /* ---------------------------------------------------------------- */
  h4 {
    font-size: 14px;
    line-height: 19px;
    font-family: $bodyHeading;
    color: $secondaryTitle;
    margin: 0 0 2px;
  }
  /* ---------------------------------------------------------------- */
  /* Company Name / Score / Thread Progress */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 12px;
    line-height: 17px;
    font-family: $bodyHeading;
    color: $greyDark;
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > ul {
    margin: 11px 0 0;
    padding-left: 24px;
    position: relative;
    border-radius: 10px;
    /* ---------------------------------------------------------------- */
    /* Progress Track */
    /* ---------------------------------------------------------------- */
    &:before {
      content: "";
      background-color: $greyXLight;
      width: 6px;
      height: calc(100% - 16px);
      position: absolute;
      left: 5px;
      top: 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Status Bar */
  /* ---------------------------------------------------------------- */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: transparent;
    @include slow-transition($props: "background-color");
    z-index: 1;
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    border-color: black;
    + .progress-section {
      border-top-color: black;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Closed */
  /* ---------------------------------------------------------------- */
  &.closed {
    padding: 0;

    &.incomplete {
      p + p {
        color: $requiredDark;
      }
    }
    &.complete {
      p + p {
        color: $primary;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.open {
    padding: 19px 16px 23px;
  }
  /* ---------------------------------------------------------------- */
  /* Incomplete */
  /* ---------------------------------------------------------------- */
  &.incomplete {
    border-left-width: 0;
    &::before {
      background-color: $required;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Complete */
  /* ---------------------------------------------------------------- */
  &.complete {
    border-left-width: 0;
    &::before {
      background-color: $valid;
    }
  }
}