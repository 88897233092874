@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input */
/* ---------------------------------------------------------------- */
.input {
  /* ---------------------------------------------------------------- */
  /* Input - Input */
  /* ---------------------------------------------------------------- */
  > input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 42px;
    padding: 0 16px;
    border-radius: 8px;
    font-family: $bodyText;
    border: 1px solid $greyXLight;
    background-color: $white;
    color: $secondaryTitle;
    font-size: 14px;
    line-height: normal;
    font-weight: normal;
    letter-spacing: normal;
    box-sizing: border-box;
    outline: none;
    resize: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    filter: none;
    @include no-user-select;
    @include fast-transition($props: "color, border-color");
    &:invalid {
      box-shadow: none;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px $white inset !important;
      background: $white;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: $white;
    }
    &:-moz-autofill-preview {
      background: $white;
    }
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
    }
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::-webkit-input-placeholder {
      color: $greyXDark !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-moz-placeholder {
      color: $greyXDark !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::-moz-placeholder {
      color: $greyXDark !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-ms-input-placeholder {
      color: $greyXDark !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::placeholder {
      color: $greyXDark !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $grey;
      @include user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $grey;
        /* ---------------------------------------------------------------- */
        /* Hover Focus */
        /* ---------------------------------------------------------------- */
        &:focus {
          border-color: $grey;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    > input {
      border-color: $error;
      color: $error;
      &::-webkit-input-placeholder {
        color: $error !important;
      }
      &:-moz-placeholder {
        color: $error !important;
      }
      &::-moz-placeholder {
        color: $error !important;
      }
      &:-ms-input-placeholder {
        color: $error !important;
      }
      &::placeholder {
        color: $error !important;
      }
      &:focus {
        border-color: $errorDark;
        color: $errorDark;
        &::-webkit-input-placeholder {
          color: $errorDark !important;
        }
        &:-moz-placeholder {
          color: $errorDark !important;
        }
        &::-moz-placeholder {
          color: $errorDark !important;
        }
        &:-ms-input-placeholder {
          color: $errorDark !important;
        }
        &::placeholder {
          color: $errorDark !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $errorDark;
          color: $errorDark;
          &::-webkit-input-placeholder {
            color: $errorDark !important;
          }
          &:-moz-placeholder {
            color: $errorDark !important;
          }
          &::-moz-placeholder {
            color: $errorDark !important;
          }
          &:-ms-input-placeholder {
            color: $errorDark !important;
          }
          &::placeholder {
            color: $errorDark !important;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    > input {
      pointer-events: none;
      color: rgba($secondaryTitle, 0.7);
      border-color: rgba($greyXLight, 0.7);
      &::-webkit-input-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &:-moz-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &::-moz-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &:-ms-input-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &::placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    > input {
      pointer-events: none;
      border-color: rgba($greyXLight, 0.7);
      &::-webkit-input-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &:-moz-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &::-moz-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &:-ms-input-placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
      &::placeholder {
        color: rgba($greyXDark, 0.7) !important;
      }
    }
  }
}
