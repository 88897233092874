@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Actions */
/* ---------------------------------------------------------------- */
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .button, button {
    &:only-child {
      margin-left: auto;
    }
  }

  &.is-loading {
    > * {
      pointer-events: none;
    }
  }
}