@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.TableContainer{
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #dddddd;
  border-radius: 12px;
  &__header{
    position: relative;
    padding: 20px 0 20px 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    h2 {
      position: relative;
      margin-bottom: 15px;
      padding: 0 15px 20px 15px;
      font-family: $mainHeading;
      font-size: 16px;
      color: $greyXXXDark;
      border-bottom: 1px solid #dddddd;
    }
    p{
      position: relative;
      padding: 0 15px;
      font-family: $bodyText;
      font-size: 14px;
      color: $greyXXXDark;
    }
  }
  &__body{
    padding: 0px 15px 35px 15px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-sizing: border-box;
  }
}